<template>
  <div class="up-center">
    <h1 class="up-main-title">Новый учебный центр</h1>
    <CreateEditForm v-model="form" @save="saveHandler" />
    <el-alert class="up-center__alert" type="warning" :closable="false">
      Укажите ниже какие курсы или целые категории курсов доступны данному
      партнеру, после чего нажмите кнопку СОХРАНИТЬ
    </el-alert>
    <CoursesTree ref="courses-tree" />
  </div>
</template>

<script>
import educationalCenterMixin from "@/mixins/admin/newEditEducationalCenter.mixin";

export default {
  name: "NewEducationalCenter",
  mixins: [educationalCenterMixin],
  methods: {
    async saveHandler() {
      try {
        const resources = this.collectResourses();

        const { data: center } = await this.$axios.post("/admin/partner", {
          partner: {
            ...this.form,
          },
          resources,
        });

        this.$router.push(`/admin/educational-center/${center.partner.id}`);

        this.$message({
          message: "Поздравляем! Учебный центр успешно создан.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText("Создание нового учебного центра");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/admin/educational-center/new-edit.scss";
</style>