import CreateEditForm from "@/components/admin/educationalСenter/CreateEditForm";
import CoursesTree from "@/components/admin/educationalСenter/CoursesTree";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  components: { CreateEditForm, CoursesTree },
  mixins: [showErrorMessageMixin],
  data: () => ({
    form: {
      name: "",
      phone: "",
      email: "",
    },
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    // Обход дерева, получение выбранных курсов, либо категории
    collectResourses() {
      return this.$refs["courses-tree"].coursesTree.reduce((acc, curr) => {
        if (!curr.childs || curr.childs.length === 0) return acc;

        const ids = [];

        curr.childs.forEach((category) => {
          if (category.isSelected) {
            ids.push(category.id);
            return;
          }

          if (!category.courses || category.courses.length === 0) return;

          category.courses.forEach((course) => {
            if (!course.isSelected) return;

            ids.push(course.id);
          });
        });

        return [...acc, ...ids];
      }, []);
    },
  },
}