<template>
  <el-form class="up-create-edit-form" :model="form" :rules="rules" ref="form">
    <el-form-item label="Наименование:" prop="name">
      <el-input v-model="form.name" placeholder="Наименование" />
    </el-form-item>
    <el-form-item label="Телефон:" prop="phone">
      <el-input v-model="phoneNumber" placeholder="Телефон" />
    </el-form-item>
    <el-form-item label="Email:" prop="email">
      <el-input v-model="form.email" placeholder="Email" />
    </el-form-item>
    <el-button
      v-if="editMode"
      class="up-create-edit-form__btn-new-password"
      type="primary"
      @click="sendPasswordHandler"
    >
      Отправить новый пароль
    </el-button>
    <el-button
      class="up-create-edit-form__btn-save"
      type="success"
      @click="saveHandler"
    >
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";
import validatableFormMixin from "@/mixins/validatableForm.mixin";

import Str from "@/helpers/Str";

const requiredValidation = [
  { required: true, message: "Необходимо заполнить", trigger: "blur" },
];

export default {
  name: "CreateEditForm",
  mixins: [validatableFormMixin, showErrorMessageMixin],
  props: {
    value: null,
    editMode: Boolean,
  },
  data: () => ({
    rules: {
      name: requiredValidation,
      phone: requiredValidation,
      email: requiredValidation,
    },
  }),
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    phoneNumber: {
      get() {
        return this.form.phone;
      },
      set(val) {
        this.form.phone = Str.phoneFormatted(val);
      },
    },
  },
  methods: {
    async saveHandler() {
      if (!(await this.isValid())) return;

      this.$emit("save");
    },
    async sendPasswordHandler() {
      try {
        await this.$axios.post(
          `/admin/partner/${this.$route.params.id}/flushpassword`
        );

        this.$message({
          message: "Поздравляем! Новый пароль отправлен на почту.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/educationalСenter/create-edit-form.scss";
</style>