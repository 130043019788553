<template>
  <div class="up-courses">
    <el-input
      v-model="searchValue"
      class="up-courses__input-search"
      placeholder="Поиск по названию"
    />
    <AppCollapse
      v-for="direction in filteredCoursesTree"
      :key="direction.id"
      :ref="direction.id"
    >
      <template slot="title">{{ direction.name }}</template>
      <AppCollapse
        v-for="category in direction.childs"
        :key="`category-${category.id}`"
        :ref="category.id"
      >
        <template slot="title">
          {{ category.name }}
          <el-checkbox
            v-model="category.isSelected"
            class="up-courses__checkbox-choose-category"
            @change="$forceUpdate()"
          >
            <span v-if="!category.isSelected">Выбрать</span>
            <span v-else>Отменить</span>
          </el-checkbox>
        </template>
        <template v-for="course in category.courses">
          <el-checkbox
            v-if="!category.isSelected"
            v-model="course.isSelected"
            :key="course.id"
            :label="course.id"
            class="up-courses__subcategory"
          >
            {{ course.name }}
          </el-checkbox>
          <p v-else :key="course.id" class="up-courses__subcategory">
            <i class="el-icon-check" /> {{ course.name }}
          </p>
        </template>
      </AppCollapse>
    </AppCollapse>
  </div>
</template>

<script>
import AppCollapse from "@/components/AppCollapse";

import filteredCousesMixin from "@/mixins/filteredCouses.mixin";
import showErrorMessage from "@/mixins/showErrorMessage.mixin";

export default {
  name: "CoursesTree",
  mixins: [filteredCousesMixin, showErrorMessage],
  components: { AppCollapse },
  props: {
    resources: Array,
    editMode: Boolean,
  },
  data: () => ({
    coursesTree: [],
  }),
  methods: {
    suitableCategories(categories) {
      return categories.reduce((acc, category) => {
        const cName = category.name.toLowerCase();
        const svalue = this.searchValue.toLowerCase();

        const isCaregorySuitable = cName.includes(svalue);

        if (!category.courses || !category.courses.length) {
          return isCaregorySuitable ? [...acc, category] : acc;
        }

        const suitableCourses = this.suitableCourses(category.courses);

        // Если курсы не подходят под фильтр, но категория подходит
        if (suitableCourses.length === 0) {
          return isCaregorySuitable ? [...acc, category] : acc;
        }

        const newCategory = { ...category };

        newCategory.childs = suitableCourses;

        this.openCollapse(category.id);

        return [...acc, newCategory];
      }, []);
    },
    suitableCourses(courses) {
      return courses.reduce((acc, course) => {
        const cName = course.name.toLowerCase();
        const svalue = this.searchValue.toLowerCase();

        return cName.includes(svalue) ? [...acc, course] : acc;
      }, []);
    },
    setSelectedResources() {
      this.coursesTree = this.coursesTree.map((direction) => {
        if (!direction.childs || direction.childs.length === 0) {
          return direction;
        }

        direction.childs.forEach((category, i) => {
          if (this.resources.includes(category.id)) {
            // Задание реактивного свойства
            this.$set(direction.childs[i], "isSelected", true);
            return;
          }

          if (!category.courses || category.courses.length === 0) return;

          category.courses.forEach((course, courseIdx) => {
            if (!this.resources.includes(course.id)) return;

            this.$set(
              direction.childs[i].courses[courseIdx],
              "isSelected",
              true
            );
          });
        });

        return direction;
      });
    },
    async fetchTree() {
      this.$nprogress.start();

      try {
        const res = await this.$axios.get("/constructor/treeFull");

        this.coursesTree = res.data;

        if (!this.editMode) return;

        this.setSelectedResources();
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.$nprogress.done();
      }
    },
  },
  created() {
    this.fetchTree();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/educationalСenter/courses-tree.scss";
</style>